import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { Modal, Table, Button } from "react-bootstrap";
import { sendStatusAction, checkAcceptAction, declinedNFTRequestAction } from "../../store/actions/send";
function SendModel() {
  let dispatch = useDispatch();
  const [sendModel, setSendModel] = useState(false);
  const [sendData, setSendData] = useState([]);

  const token = localStorage.getItem("jwtToken")  

  useEffect(() => {
    if (token !== null) {
      dispatch(sendStatusAction({ token, loader: true }))
        .then(async (res) => {
          if (res.data.sendDataLength.length > 0) {
            setSendModel(true);
            setSendData(res.data.sendDataLength);
          } else {
            setSendModel(false);
          }
        }).catch((error) => {
          setSendModel(false);
        });
    }
  }, []); // eslint-disable-line

  const checkAcceptNft = async(val) =>{
    const result = await dispatch(checkAcceptAction(val));
            const { error, success, message } = result.data;
            if (success || error) {
              dispatch(sendStatusAction({ token, loader: true })).then((rs) => {
                setSendData(rs.data.sendDataLength);
                if (rs.data.sendDataLength.length === 0) {
                  setSendModel(false);
                  if (error) {
                    toast.error(message, {
                      toastId: "error",
                    });
                  }
                  if (success) {
                    toast.success(message, {
                      toastId: "receivenft",
                    });
                  }
                }
              });
            }
  }

  const handleSendModel = () => setSendModel(false);
  const acceptOffer = async(val) => {
    await checkAcceptNft(val)
  };
  const declineOffer = (val) => {
     dispatch(declinedNFTRequestAction(val)).then((data)=>{
       if(data.data.success){
        window.location.reload();
       }
     }).catch((e)=>{
       console.log(e,"Declined NFT request");
     })

  };

  return (
    <>
      <Modal
        show={sendModel}
        className="sendModal modal"
        onHide={handleSendModel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong className="text-info"> Accept Free NFT</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <h5>
          You have been sent a NFT from the below details, please ‘Accept’ or ‘Decline’ the transaction.
          </h5>
          <Table className="freenft-table">
            <thead>
              <tr>
                <th>NFT ID</th>
              </tr>
            </thead>
            <tbody>
              {sendData.map((val, i) => {
                return (
                  <tr key={i}>
                    <td>

                    {val.nft_id ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://bithomp.com/explorer/${val.nft_id}`}
                          className=""
                        >
                          {val.nft_id.substring(0, 9)} *****{" "}
                      {val.nft_id.substring(val.nft_id.length - 5)}
                         
                        </a>
                      ) : (
                        ""
                      )}

                    </td>
                    <td align="center">
                      <Button
                        variant="success"
                        onClick={() => acceptOffer(val)}
                        className="BuyNFT buttonMobile_None"
                      >
                        Accept
                      </Button>

                      <Button
                        variant="success"
                        onClick={() => acceptOffer(val)}
                        className="BuyNFT buttonDesktop_None"
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </Button>
                    </td>
                    <td align="center">
                      <Button
                        variant="danger"
                        onClick={() => declineOffer(val)}
                        className="BuyNFT buttonMobile_None"
                      >
                        Decline
                      </Button>

                      <Button 
                      className="BuyNFT buttonDesktop_None"
                      onClick={() => declineOffer(val)}
                      variant="danger">
                      <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}
export default SendModel;
